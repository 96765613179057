import React from "react"
import DeveloperNewsletterHelmet from "../components/SEO/DeveloperNewsletterHelmet"
import NewsletterForm from "../components/NewsletterForm"
import { NEWSLETTER_DEVELOPER_LIST_ID } from "../utils/constants"
import Typography from "../components/Typography"

const DeveloperNewsletter = () => {
    return (
        <div className="bg-[url('/assets/img/newsletter_bg_mobile.png')] xl:bg-[url('/assets/img/newsletter_bg.jpg')] bg-cover bg-center">
            <img
                src="/assets/img/horizen_logo.svg"
                alt="Horizen Logo"
                className="absolute top-0 lg:top-4 left-0 lg:left-4 p-4"
            />
            <div className="flex flex-col items-center justify-center min-h-screen p-6">
                <DeveloperNewsletterHelmet />
                <Typography.H3 className="mb-12 lg:mb-0 text-center w-full lg:w-2/3">
                    Join our Developer Newsletter List to keep up to date with
                    all Developer related activities, updates, upgrades and
                    releases within the Horizen Ecosystem!
                </Typography.H3>
                <NewsletterForm
                    newsletterID={NEWSLETTER_DEVELOPER_LIST_ID}
                    inputsOnly
                />
            </div>
        </div>
    )
}

export default DeveloperNewsletter
