import React from "react"
import { TypographyBodyProps } from "../../types/Typography"

const Body1: React.FC<TypographyBodyProps> = ({
    children,
    className = "",
    ...rest
}) => {
    return (
        <p
            className={`font-proxima text-horizen-content-grey text-[16px] leading-[22px] md:text-[18px] md:leading-[25px] ${className}`}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...rest}
        >
            {children}
        </p>
    )
}

export default Body1
