import H1 from "./H1"
import H2 from "./H2"
import H3 from "./H3"
import Body1 from "./Body1"
import Body2 from "./Body2"
import Link1 from "./Link1"

const Typography = {
    H1,
    H2,
    H3,
    Body1,
    Body2,
    Link1,
}

export default Typography
