import React from "react"
import { Helmet } from "react-helmet"

const DeveloperNewsletterHelmet = () => {
    return (
        <Helmet>
            <title>Horizen Developer Newsletter</title>
            <meta property="og:title" content="Horizen Developer Newsletter" />
            <meta name="twitter:title" content="Horizen Developer Newsletter" />
            <meta name="theme-color" content="#041742" />
            <meta
                name="description"
                content="Join our Developer Newsletter List to keep up to date with all Developer related activities, updates, upgrades and releases within the Horizen Ecosystem!"
            />
            <meta
                property="og:description"
                content="Join our Developer Newsletter List to keep up to date with all Developer related activities, updates, upgrades and releases within the Horizen Ecosystem!"
            />
            <meta
                name="twitter:description"
                content="Join our Developer Newsletter List to keep up to date with all Developer related activities, updates, upgrades and releases within the Horizen Ecosystem!"
            />
            <meta
                property="og:image"
                content="https://www.horizen.io/assets/images/meta/developerNewsletter.jpg"
            />
            <meta
                name="twitter:image"
                content="https://www.horizen.io/assets/images/meta/developerNewsletter.jpg"
            />
            <meta name="twitter:card" content="summary_large_image" />
            <meta
                name="keywords"
                content="horizen ambassador, ambassador, horizen ambassador program, zencash ambassador, horizen community, become an ambassador, zen cash ambassador"
            />
            <link
                rel="canonical"
                href="https://www.horizen.io/developer-newsletter/"
            />
            <meta
                property="og:url"
                content="https://www.horizen.io/developer-newsletter/"
            />
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content="Horizen" />
            <meta name="twitter:site" content="@horizenglobal" />
            <meta name="twitter:creator" content="@horizenglobal" />
        </Helmet>
    )
}

export default DeveloperNewsletterHelmet
