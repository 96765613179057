import React from "react"
import { TypographyLinkProps } from "../../types/Typography"

export interface Link1Props extends TypographyLinkProps {
    showBrackets?: boolean
}

const Link1: React.FC<Link1Props> = ({
    children,
    className = "",
    showBrackets = false,
    ...rest
}) => {
    return (
        <a
            className={`font-proxima cursor-pointer text-links text-[16px] leading-[22px] ${className}`}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...rest}
        >
            {showBrackets && "[ "}
            {children}
            {showBrackets && " ]"}
        </a>
    )
}

export default Link1
