import React from "react"
import { TypographyHeadingProps } from "../../types/Typography"

const H3: React.FC<TypographyHeadingProps> = ({
    children,
    className = "",
    ...rest
}) => {
    return (
        <h3
            className={`font-proxima text-white font-bold text-[22px] leading-[27px] ${className}`}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...rest}
        >
            {children}
        </h3>
    )
}

export default H3
